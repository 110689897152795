import axios from 'axios';
import config from '../../../config.js';
import { store } from '../../../store/index.js';

const redirectForLogin = () => {
    window.location.href = '/';
   }
   
   axios.interceptors.response.use((response) => response,  (error) => {
     const statusCode = error.response.data.code;
     console.log(statusCode);
     return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
   });

export function uploadPrescriptionForMed(
    userId,
    requestData,
    onUploadProgress
) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
        .post(
            `${config.pharmacyService}/ops/digitisation/add-multiple-files?userId=${userId}`,
            requestData,
            {
                headers,
                onUploadProgress,
            }
        )
        .then((resp) => {
            const responseBody = resp.data;
            if (responseBody.message === 'success') {
                return responseBody;
            } else {
                const err = new Error('Invalid response');
                err.data = responseBody;
                throw err;
            }
        })
        .catch((err) => {
            throw err;
        });
}

export function removeFileFromPrescriptionMeds(
    fileId,
    prescriptionId,
) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }

    return axios
        .post(
            `${config.pharmacyService}/ops/digitisation/prescriptions/file/remove`,
            { prescriptionId, fileId },
            {
                headers,
            }
        )
        .then((res) => {
            const responseBody = res.data;
            if (responseBody.message === 'success') {
                return responseBody;
            } else {
                const err = new Error('Invalid response');
                err.data = responseBody;
                throw err;
            }
        })
        .catch((err) => {
            throw err;
        });
}

export function submitDigitsationRequestMeds(
    pharmacyPrescriptionId,
    addressId,
    patientId,
    userId
) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
        .post(
            `${config.pharmacyService}/ops/digitisation/submit-request?userId=${userId}`,
            {
                pharmacyPrescriptionId,
                addressId,
                patientId,
            },
            {
                headers,
            }
        )
        .then((resp) => {
            const responseBody = resp.data;
            if (responseBody.message === 'success') {
                return responseBody;
            } else {
                const err = new Error('Invalid response');
                err.data = responseBody;
                throw err;
            }
        })
        .catch((err) => {
            throw err;
        });
}

export function uploadPrescriptionForMedDashboard(userId) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }

    return axios
        .get(`${config.pharmacyService}/ops/digitisation/upload-prescription?userId=${userId}`, {
            headers,
        })
        .then((resp) => {
            const responseBody = resp.data;
            if (responseBody.message === 'success') {
                return responseBody;
            } else {
                const err = new Error('Invalid response');
                err.data = responseBody;
                throw err;
            }
        })
        .catch((err) => {
            throw err;
        });
}

export function getUserInfoByPhone(phone) { 
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
        .get(`${config.pharmacyService}/ops/userInfo/phone/${phone}`, {
            headers,
        })
        .then((resp) => {
            const responseBody = resp.data;
            return responseBody;
        })
        .catch((err) => {
            throw err;
        });
}
export function getDigitisationId(orderId) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
    .get(`${config.pharmacyService}/ops/digitisationId/${orderId}`, {
        headers,
    })
    .then((resp) => {
        const responseBody = resp.data;
        return responseBody;
    })
    .catch((err) => {
        throw err;
    });
}

export async function getCartsFromDigitisationId(digitisationId){
    try {
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
        const { data : responseBody } = await axios.get(`${config.pharmacyService}/selectPartner/digitisationId/${digitisationId}`, {headers})
        if (responseBody.message === 'success') {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
} 


export async function modifyCartData(cartId , cartData) {
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.post(`${config.pharmacyService}/cart/prescription/cartId/${cartId}/modify`, {items : cartData },{headers})
         if (responseBody.message === 'success') {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}

export async function getCartDetails(cartId){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.get(`${config.pharmacyService}/cart/prescription/cartId/${cartId}`, {headers})
         if (responseBody.message === 'success') {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}

export async function placeFailedOrderCart(failedOrderId, useCartId){
    try {
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
        const responseBody = await axios.post(`${config.pharmacyService}/ops/fix-failed-order`, {"useCartId" : useCartId , "failedOrderId" : failedOrderId}, { headers });
        return responseBody.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        } else if (err.request) {
            return { message: 'No response received from the server' };
        } else {
            return { message: 'Error during request setup' };
        }
    }
}

export async function placeOrderByCartId(cartId){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
        const { data : responseBody }  = await axios.post(`${config.pharmacyService}/ops/place-order-by-cartId?cartId=${cartId}`, {},{headers});
         if (responseBody.message === 'success') {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}
export async function cancelAndRefundOrder(orderId) {
    try {
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
        const response = await axios.post(`${config.pharmacyService}/ops/cancel-and-refund-order?orderId=${orderId}`, {}, { headers });
        return response.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        } else if (err.request) {
            return { success: false, message: 'No response received from the server' };
        } else {
            return { success: false, message: 'Error during request setup' };
        }
    }
}

export async function getOpsCallReasons(){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.get(`${config.pharmacyService}/v2/ops/getOpsCallReasons`, {headers})
         if (responseBody.success) {
            return responseBody.reasons;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}

export async function sendCommunicationsToUser(orderId , callReasonId){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }

         const { data : responseBody } = await axios.post(`${config.baseUrl}/new-ops/pharmacy/send-communications-to-user`, { orderId , callReasonId }, {headers})
         if (responseBody.success) {
            return {success : true, message : 'Communications Sent Successfully'};
        } else {
            return {success : false, message : responseBody.message || 'Failed To Sent Communication'};
        }
    }catch(err){
        if(err.response && err.response.data && err.response.data.message){
            return {success : false, message : `Failed To Sent Communication - ${err.response.data.message}` };
        }
        return {success : false, message : `Failed To Sent Communication` };
    }
}

export async function markFollowUpForPharmacy(orderId, followUpReason, followUpRequiredFrom, appDate, followUpTime){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.post(`${config.pharmacyService}/v2/ops/markFollowUp`, { orderId , followUpReason, followUpRequiredFrom, followUpDate : appDate, followUpTime}, {headers})
         if (responseBody.success) {
            return true;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}

export async function getFollowUpRequestsForOrderId(orderId){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.get(`${config.pharmacyService}/v2/ops/followUpRequests/${orderId}`, {headers})
         if (responseBody.success) {
            return responseBody.followUpRequests;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}

export async function markFollowUpsCompleted(followUpIds){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.post(`${config.pharmacyService}/v2/ops/completeFollowUp`, { followUpIds}, {headers})
         if (responseBody.success) {
            return true;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}


export async function downloadPHOrdersCsv(filters) {
  try {
    if (filters.status && filters.status === "all") {
      filters.status = null;
    }

    const urlQuery = new URLSearchParams(filters).toString();
    const state = store.getState();
    const headers = {};

    if (state.user.token) {
      headers.authorization = state.user.token;
    }

    const response = await axios.get(
      `${config.pharmacyService}/ops/downloadPhOrdersCsv?${urlQuery}`,
      { headers, responseType: "blob" }
    );

    const url = window.URL.createObjectURL(new Blob([response.data], { type: "text/csv" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "pharmacy_orders.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return true;
  } catch (err) {
    console.error("Error downloading CSV:", err);
    return false;
  }
}

export async function getOpsUserRole() {
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.get(`${config.pharmacyService}/v2/ops/user/get-ops-user-role`, {headers})
         if (responseBody.success) {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            return true;
        }
    }catch(err){
        return true;
    }
}
export async function getpharmacyOrders(filters, appliedOrderBy){
    try{
        if (filters.status && filters.status === 'all') {
            filters.status = null;
        }
        filters.orderBy = appliedOrderBy;
        const urlQuery = new URLSearchParams(filters).toString();
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.get(`${config.pharmacyService}/v2/ops/pharmacy/orders?${urlQuery}`, {headers})
         if (responseBody.success) {
            return responseBody.orders;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}

export async function getpharmacyOrderCount(filters){
    try{
        if (filters.status && filters.status === 'all') {
            filters.status = null;
        }
        const urlQuery = new URLSearchParams(filters).toString();
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.get(`${config.pharmacyService}/v2/ops/pharmacy/orders/count?${urlQuery}`, {headers})
         if (responseBody.success) {
            return responseBody.count;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}


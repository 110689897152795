import React from 'react';
import { connect } from 'react-redux';
// sign out icon from fa icons
import { FaSignOutAlt, FaAlignJustify, FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import StyledTappable from '../common/StyledTappable';
import NotificationPanel from './NotificationPanel';
import ReimbursementNotificationContainer from './notification/ReimbursementNotificationContainer';
import { FaComments } from 'react-icons/fa';
// getting actions
import { toggleSidebar } from '../../actions';
import searchIcon from '../../images/material-symbols_manage-search-rounded.svg'
import PolicySearchModal from '../customer-support/comments/policy-search-modal';
import { withRouter } from 'react-router-dom';

const Nav = styled.nav`
  background-color: white;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  color: white;
`;

const ToggleIcon = styled(FaAlignJustify)`
  color: #3a2ca0;
`;

const SignOutButton = styled(StyledTappable)`
  margin: 0rem 1rem;
`;
class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isPolicySearchModalOpen: false,
    };
  }

  handleLogOut = () => {
    this.props.history.push('/logout');
  };

  toggleSideBar = () => {
    if (window.location.pathname !== '/') this.props.toggleSideBar();
  };

  showNotification = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <Nav
        className="navbar navbar-dark fixed-top"
        style={{ boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
      >
        <StyledTappable onTap={() => this.toggleSideBar()}>
          <ToggleIcon />
        </StyledTappable>
        <a
          className="navbar-brand"
          href="https://getvisitapp.com/"
          style={{
            color: '#3A2CA0',
            fontWeight: 'bold',
            fontFamily: 'Inter, sans-serif',
          }}
        >
          Visit Operations Dashboard
        </a>
        {window.location.pathname !== '/' && (
          <div>
            <RightContainer>
              <NotificationPanel />
              {/* {this.props.user.showReimbursement ?
                // <div className="">
                  <div className="ml-2" onClick={()=>this.showNotification()}>
                    <FaComments />
                    {this.state.isOpen && <ReimbursementNotificationContainer close={this.close}/>}
                  </div>
                // </div>
                :''} */}
              <div style={{ paddingLeft: '10px' }}>
                <img src={searchIcon} alt="search-policy" onClick={() => this.setState({ isPolicySearchModalOpen: true })} />
              </div>
              <PolicySearchModal isOpen={this.state.isPolicySearchModalOpen} toggle={() => this.setState({ isPolicySearchModalOpen: false })} />
              <SignOutButton onTap={this.handleLogOut}>
                <FaSignOutAlt style={{ color: '#3A2CA0' }} /> Logout
              </SignOutButton>
            </RightContainer>
          </div>
        )}
      </Nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleSideBar: () => {
    dispatch(toggleSidebar());
  },
});

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(
  // null,
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavBar));

import React, { Component } from 'react';
import { debounce } from '../../utils/debounce';
import Select from 'react-select';
import { Async, components } from 'react-select';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import { toast } from 'react-toastify';
import { TimePicker } from 'antd';
import styled from 'styled-components';
import { BoldText, RegularText } from '../common/Text';

import {
  searchDoctors,
  fetchUsersRelative,
  getVerticals,
  fetchUserPolicies,
  fetchUsersRelativeByphone,
  fetchUserPoliciesByPhone,
  getDoctorSlots,
} from '../../services/api/offline-consult';

import moment from 'moment';
import LoadingComponent from '../common/LoadingComponent';

// import { useLink } from 'valueLink';
// import { Input } from 'linked-controls';

const timeSlots = [
  { value: 13, label: '06:00:00 AM - 06:30:00 AM' },
  { value: 14, label: '06:30:00 AM - 07:00:00 AM' },
  { value: 15, label: '07:00:00 AM - 07:30:00 AM' },
  { value: 16, label: '07:30:00 AM - 08:00:00 AM' },
  { value: 17, label: '08:00:00 AM - 08:30:00 AM' },
  { value: 18, label: '08:30:00 AM - 09:00:00 AM' },
  { value: 19, label: '09:00:00 AM - 09:30:00 AM' },
  { value: 20, label: '09:30:00 AM - 10:00:00 AM' },
  { value: 21, label: '10:00:00 AM - 10:30:00 AM' },
  { value: 22, label: '10:30:00 AM - 11:00:00 AM' },
  { value: 23, label: '11:00:00 AM - 11:30:00 AM' },
  { value: 24, label: '11:30:00 AM - 12:00:00 PM' },
  { value: 25, label: '12:00:00 PM - 12:30:00 PM' },
  { value: 26, label: '12:30:00 PM - 01:00:00 PM' },
  { value: 27, label: '01:00:00 PM - 01:30:00 PM' },
  { value: 28, label: '01:30:00 PM - 02:00:00 PM' },
  { value: 29, label: '02:00:00 PM - 02:30:00 PM' },
  { value: 30, label: '02:30:00 PM - 03:00:00 PM' },
  { value: 31, label: '03:00:00 PM - 03:30:00 PM' },
  { value: 32, label: '03:30:00 PM - 04:00:00 PM' },
  { value: 33, label: '04:00:00 PM - 04:30:00 PM' },
  { value: 34, label: '04:30:00 PM - 05:00:00 PM' },
  { value: 35, label: '05:00:00 PM - 05:30:00 PM' },
  { value: 36, label: '05:30:00 PM - 06:00:00 PM' },
  { value: 37, label: '06:00:00 PM - 06:30:00 PM' },
  { value: 38, label: '06:30:00 PM - 07:00:00 PM' },
  { value: 39, label: '07:00:00 PM - 07:30:00 PM' },
  { value: 40, label: '07:30:00 PM - 08:00:00 PM' },
  { value: 41, label: '08:00:00 PM - 08:30:00 PM' },
  { value: 42, label: '08:30:00 PM - 09:00:00 PM' },
  { value: 43, label: '09:00:00 PM - 09:30:00 PM' },
  { value: 44, label: '09:30:00 PM - 10:00:00 PM' },
  { value: 45, label: '10:00:00 PM - 10:30:00 PM' },
  { value: 46, label: '10:30:00 PM - 11:00:00 PM' },
  { value: 47, label: '11:00:00 PM - 11:30:00 PM' },
];

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled.div`
  font-size: 1rem;
  width: ${(props) => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;

const DetailText = styled(RegularText)`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#787778'};
  margin-left: 0.25rem;
`;

const DoctorSlotBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;


class PlaceRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      searchQuery: '',
      userId: '',
      phone: '',
      dateKey: null,
      relativeResult: [],
      relativeLoading: false,
      verticalsResult: [],
      verticalLoading: false,
      showFormWarning: false,
      alertMessage: 'None',
      loading: false,
      starhealthUser: false,
      policyResult: [],
      policyNumber: null,
      dependantResult: [],
      userDetails: null,
      doctorSlots: null,
      appointmentStartTime: null,
      appointmentEndTime: null,
      externalSlotInfo: null,
      doctorSlotModalInfo: {
        modal: false,
        data: [],
        date: ''
      }
    };
    this.doctorSelectRef = React.createRef();
    this.relativeSelectRef = React.createRef();
    this.slotSelectRef = React.createRef();
    this.verticalSelectRef = React.createRef();
    this.policySelectRef = React.createRef();
    this.dependantSelectRef = React.createRef();
    this.onAlertDismiss = this.onAlertDismiss.bind(this);

    this.debounceFetchData = debounce(() => {
      this.onPhoneIdInputBlur(0);
    }, 800);
  }

  toggle = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  onAlertDismiss() {
    this.setState({ showFormWarning: false });
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleDoctorResultClick = (doctorId) => {
    this.setState({ doctorId });
  };

  handleRelativeResultClick = (relativeId) => {
    this.setState({ relativeId });
  };

  componentWillMount() {
    this.fetchVerticals();
  }

  handleUserIdChange = () => {
    const { userId } = this.state;
    if (!userId) {
      return;
    }
    this.setState({ relativeLoading: true });
    fetchUsersRelative(userId)
      .then((response) => {
        if (response.isStarhealthUser) {
          this.setState({
            starhealthUser: true,
            policyResult: [],
            dependantResult: [],
          });
          //show loader and msg that this is a starhealth user please wait while we fetch policies
          fetchUserPolicies(userId).then((response) => {
            if (response && !response.errorMessage) {
              console.log(response, 'Policies list');
              const policies = response.response.map((policy) => {
                return {
                  label: `${policy.policyNumber}\n(${policy.productName})`,
                  value: policy.policyNumber,
                };
              });

              const dependants = {};
              response.response.map((policy) => {
                dependants[policy.policyNumber] = policy.insuredDetails;
              });
              this.setState({
                policyResult: policies,
                dependantResult: dependants,
                loading: false,
              });
            }
          });
        } else {
          this.setState({
            starhealthUser: false,
          });
        }
        this.setState({
          userId,
          relativeResult: response.result || [],
        });
      })
      .finally(() => {
        this.setState({ relativeLoading: false, loading: false });
      });
  };

  fetchVerticals = () => {
    this.setState({ verticalLoading: true });
    getVerticals()
      .then((response) => {
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.verticalId, label: element.verticalName });
        });
        this.setState({ verticalsResult: arr });
      })
      .finally(() => {
        this.setState({ verticalLoading: false });
      });
  };

  handleDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    this.setState({ dateKey });
  };

  handleSlotIdChange = (event) => {
    const slotId = event.target.value;
    if (!slotId) {
      return;
    }
    this.setState({ slotId });
  };

  handleBadFormInput = (
    doctorId,
    dateKey,
    slot,
    userId,
    centerId,
    policyNumber,
    dependant
  ) => {
    let alertMessage = 'Bad Input';
    if (!dateKey) {
      alertMessage = 'You need to select a date';
    }
    if (!slot) {
      alertMessage = 'You need to select a slot';
    }
    if (!userId) {
      alertMessage = 'You need to select a user or valid phone/email';
    }
    if (!doctorId) {
      alertMessage = 'You need to select a vertical and a doctor';
    }
    if (!centerId) {
      alertMessage = 'No Center Found for this doctor';
    }
    if (!policyNumber) {
      alertMessage = 'No Policies found for this user';
    }
    if (!dependant) {
      alertMessage = 'No Dependents found in this Policy';
    }
    // show alert
    this.setState({ showFormWarning: true, alertMessage });
  };

  placeRequest = () => {
    const { dateKey, userId, doctorSlots, appointmentStartTime, appointmentEndTime, externalSlotInfo } = this.state;
    console.log(
      { internalState: this.doctorSelectRef.current.select.state.value, dateKey, appointmentStartTime },
      '[placeRequest]'
    );
    const doctorId =
      this.doctorSelectRef.current.select.state.value.value || false;
    let relativeId;
    if (this.state.starhealthUser) {
      relativeId = -1;
    } else {
      relativeId = this.relativeSelectRef.current.state.value.value || -1;
    }
    let slotId = null;
    if(!doctorSlots) {
    slotId = this.slotSelectRef.current.state.value
      ? this.slotSelectRef.current.state.value.value
      : false;
    }

    const centerId =
      this.doctorSelectRef.current.select.state.value.centerId || false;

    if (relativeId === -1) {
      relativeId = null;
    }

    let policyNumber =
      (this.policySelectRef.current &&
        this.policySelectRef.current.state.value &&
        this.policySelectRef.current.state.value.value) ||
      -1;
    let dependant =
      (this.dependantSelectRef.current &&
        this.dependantSelectRef.current.state.value &&
        this.dependantSelectRef.current.state.value.value) ||
      -1;

    if(doctorSlots){ 
      const slotCheckResult = this.checkWhetherApptDateWithinSlots(
        `${dateKey} ${appointmentStartTime}`
      );
      if (!slotCheckResult) {
        toast.error(
          'The selected Appointment Date/Time is not within the Available Doctor Slots, edit the slots or select different date/time'
        );
        return;
      }  
    }
    if (!doctorId || !dateKey || (!slotId && !doctorSlots) || !userId || !centerId) {
      // show alert
      this.handleBadFormInput(
        doctorId,
        dateKey,
        slotId,
        userId,
        centerId,
        policyNumber,
        dependant
      );
      return;
    }

    this.setState({
      loading: true,
      policyNumber: policyNumber,
    });

    this.props
      .submit(
        doctorId,
        centerId,
        userId,
        relativeId,
        moment(dateKey).format('YYYYMMDD'),
        slotId,
        policyNumber,
        dependant,
        {...this.state.externalSlotInfo, slotKey: appointmentStartTime, slotKeyEndTime: appointmentEndTime}
      )
      .then((result) => {
        if (result) {
          this.setState({ modalVisible: false });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onPolicyBlur = () => {
    let policyNumber = this.policySelectRef.current.state.value?.value || -1;
    if (
      this.dependantSelectRef.current.state.value != null &&
      this.dependantSelectRef.current.state.value.hasOwnProperty('label')
    ) {
      this.dependantSelectRef.current.state.value.label = '';
    }
    this.dependantSelectRef.current.state.value = '';
    this.setState({
      policyNumber,
    });
  };

  onUserIdInputBlur = (showToasty = 1) => {
    console.log(this.doctorSelectRef.current.state, 'doctor selection value');
    const { userId } = this.state;
    this.setState({
      relativeLoading: true,
      loading: true,
      policyResult: [],
      dependantResult: [],
    });
    fetchUsersRelative(userId)
      .then((response) => {
        if (response.isStarhealthUser) {
          showToasty &&
            toast.info(
              'Since its a star health user please wait while we are fetching its policies.'
            );
          this.setState({
            starhealthUser: true,
            loading: true,
          });
          //show loader and msg that this is a starhealth user please wait while we fetch policies
          fetchUserPolicies(userId).then((response) => {
            console.log(
              response,
              response.errorMessage,
              'fetchUserPolicies/Response'
            );
            if (response && !response.errorMessage) {
              const policies = response.response.map((policy) => {
                return {
                  label: policy.policyNumber,
                  subLabel: policy.productName,
                  value: policy.policyNumber,
                };
              });

              const dependants = {};
              response.response.forEach((policy) => {
                dependants[policy.policyNumber] = policy.insuredDetails;
              });
              this.setState({
                policyResult: policies,
                dependantResult: dependants,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
              showToasty && toast.error('No Policies found for this user');
            }
          });
        } else {
          this.setState({
            starhealthUser: false,
            loading: false,
          });
        }
        const relatives = [
          {
            value: -1,
            label: 'Self',
          },
        ].concat(
          response.data.map((ele) => ({
            label: `${ele.relativeName}(${ele.relationName})`,
            value: ele.relativeId,
          }))
        );
        this.setState({
          relativeResult: relatives,
          userDetails: response.userDetails,
        });
      })
      .finally(() => {
        this.setState({
          relativeLoading: false,
          // loading: false
        });
      });
  };

  onPhoneIdInputBlur = (showToasty = 1) => {
    console.log(
      this.doctorSelectRef.current.state,
      'doctor selection value onPhoneIdBlur'
    );
    const { phone } = this.state;
    this.setState({
      relativeLoading: true,
      loading: true,
      policyResult: [],
      dependantResult: [],
    });
    fetchUsersRelativeByphone(phone)
      .then((response) => {
        if (response.isStarhealthUser) {
          showToasty &&
            toast.info(
              'Since its a star health user please wait while we are fetching its policies.'
            );
          this.setState({
            starhealthUser: true,
            loading: true,
          });
          //show loader and msg that this is a starhealth user please wait while we fetch policies
          fetchUserPoliciesByPhone(phone).then((response) => {
            console.log(
              response,
              response.errorMessage,
              'fetchUserPolicies/Response'
            );
            if (response && !response.errorMessage) {
              const policies = response.response.map((policy) => {
                return {
                  label: policy.policyNumber,
                  subLabel: policy.productName,
                  value: policy.policyNumber,
                };
              });

              const dependants = {};
              response.response.forEach((policy) => {
                dependants[policy.policyNumber] = policy.insuredDetails;
              });
              this.setState({
                policyResult: policies,
                dependantResult: dependants,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
              showToasty && toast.error('No Policies found for this user');
            }
          });
        } else {
          this.setState({
            starhealthUser: false,
            loading: false,
          });
        }
        const relatives = [
          {
            value: -1,
            label: 'Self',
          },
        ].concat(
          response.data.map((ele) => ({
            label: `${ele.relativeName}(${ele.relationName})`,
            value: ele.relativeId,
          }))
        );
        this.setState({
          relativeResult: relatives,
          userDetails: response.userDetails,
          userId: response.userDetails.userId,
        });
      })
      .finally(() => {
        this.setState({
          relativeLoading: false,
          // loading: false
        });
      });
  };

  getNetworkName = (sourceId) => {
    switch (sourceId) {
      case 1: {
        return 'Practo';
      }
      case 2: {
        return 'Crediheatlh';
      }
      case 3: {
        return 'Medibuddy';
      }
      case 4: {
        return 'Visit Online';
      }
      case 5: {
        return 'Docprime';
      }
      case 6: {
        return 'Visit Onboarded';
      }
      default: {
        return 'Unknown';
      }
    }
  };

  searchDoctors = (val) => {
    const verticalId = this.verticalSelectRef.current.state.value.value;
    console.log(
      this.verticalSelectRef.current.state.value.value,
      'vertical value'
    );
    return searchDoctors(val, verticalId)
      .then((response) => {
        return response.data || [];
      })
      .then((result) =>
        result.map((ele) => {
          return {
            label: `${ele.doctorName} - ${ele.networkCenterTitle} - ${ele.centerCity} - ${ele.networkSourceName}`,
            value: ele.doctorId,
            centerId: ele.networkCenterId,
            externalCenterId: ele.externalCenterId,
            externalDoctorId: ele.externalDoctorId,
            centerKey: ele.centerKey
          };
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  handleDoctorSelection = (doctorsData) => {
    console.log(doctorsData, 'doctorsData123');
    if (
      doctorsData.centerKey &&
      doctorsData.externalCenterId &&
      doctorsData.externalDoctorId
    ) {
      this.setState({ loading: true });
      getDoctorSlots({
        doctorId: doctorsData.value,
        networkCenterId: doctorsData.centerId,
      })
        .then((res) => {
          console.log(
            { slots: res.data, showSlot: res.data.showSlot },
            'getDoctorSlots'
          );
          this.setState({ doctorSlots: res.data.showSlot ? res.data.slots : null });
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => this.setState({ loading: false }));
    }
  };

  checkWhetherApptDateWithinSlots = (date) => {
    let isWithinSlots = false;
    const apptDate = new Date(date);
    const apptDOW = apptDate.getDay() + 1;
    const apptTime = moment(apptDate).format('HH:mm:ss');
    console.log('checkWhetherApptDateWithinSlots', {
      date,
      apptDate,
      apptDOW,
      apptTime,
    });
    const apptFormatDate = moment(date).format('YYYY-MM-DD');
    console.log('checkWhetherApptDateWithinSlots', {
      date,
      apptDate,
      apptDOW,
      apptTime,
      apptFormatDate,
    });
    const correctSlots = this.state.doctorSlots.filter(
      (slot) => slot.formattedDateKey === apptFormatDate
    );
    console.log('checkWhetherApptDateWithinSlotsData', { correctSlots });

    if (correctSlots[0] && correctSlots[0].slots) {
      for (let slotValue of correctSlots[0].slots) {
        const startTime = moment(slotValue.slotKey, 'hh:mm A').format(
          'HH:mm:ss'
        );
        const endTime = moment(slotValue.slotKeyEndTime, 'hh:mm A').format(
          'HH:mm:ss'
        );
        if (apptTime >= startTime && apptTime <= endTime) {
          isWithinSlots = true;
          if (slotValue.externalSlotInfo) {
            this.setState({ externalSlotInfo: slotValue.externalSlotInfo });
          }
        }
      }
    }
    if (!isWithinSlots) {
      this.setState({
        showFormWarning: true,
        alertMessage:
          'The selected Appointment Date/Time is not within the Available Doctor Slots, edit the slots or select different date/time',
      });
    }
    return isWithinSlots;
  };

  render() {
    const {
      relativeResult,
      relativeLoading,
      modalVisible,
      verticalsResult,
      verticalLoading,
      loading,
    } = this.state;
    const { showModal } = this;
    const userIdLink = Link.state(this, 'userId').onChange(() => {
      this.onUserIdInputBlur(0);
    });
    const phoneIdLink = Link.state(this, 'phone').onChange(() => {
      this.debounceFetchData();
    });
    const dependentOptions =
      this.state.policyNumber &&
      this.state.dependantResult[this.state.policyNumber]
        ? this.state.dependantResult[this.state.policyNumber].map((dep) => ({
            label: dep.name,
            value: dep,
          }))
        : [];

    const SingleValue = (props) => {
      const [{ label, subLabel }] = props.getValue();

      return (
        <components.SingleValue {...props}>
          <span>
            {label} ({subLabel})
          </span>
        </components.SingleValue>
      );
    };

    const GetOptionLabel = ({ label, subLabel }) => (
      <>
        <div> {label} </div>
        <div> ({subLabel}) </div>
      </>
    );

    return (
      <div>
        <button
          type="button"
          style={{
            background: '#714FFF',
            borderColor: '#714FFF',
            width: '106%',
            margin: '0px',
            borderRadius: '5px',
            border: '0px',
            color: 'white',
            padding: '12px 12px',
            fontSize: '14px',
            fontWeight: '600',
            fontFamily: 'Inter, sans-serif',
            justifyContent: 'flex-start',
          }}
          onClick={showModal}
        >
          Book Request
        </button>
        <Modal
          centered
          isOpen={modalVisible}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader className="revamp-modal-header" toggle={this.toggle}>
            Place Request
          </ModalHeader>
          <ModalBody>
            <form>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Select Vertical:
                    </Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={true}
                      ref={this.verticalSelectRef}
                      defaultValue={
                        relativeResult.length > 0 && relativeResult[0]
                      }
                      options={verticalsResult}
                      isLoading={verticalLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Search Doctor:
                    </Label>
                  </Col>
                  <Col>
                    <Async
                      loadOptions={this.searchDoctors}
                      ref={this.doctorSelectRef}
                      onChange={(e) => {
                        this.handleDoctorSelection(e);
                      }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      User Id:
                    </Label>
                  </Col>
                  <Col>
                    <Input
                      className="form-control"
                      valueLink={userIdLink}
                      type="number"
                      onBlur={this.onUserIdInputBlur}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      phone/email:
                    </Label>
                  </Col>
                  <Col>
                    <Input className="form-control" valueLink={phoneIdLink} />
                  </Col>
                </Row>
              </FormGroup>
              {!this.state.starhealthUser && (
                <FormGroup>
                  <Row>
                    <Col className="col-3">
                      <Label
                        style={{
                          fontFamily: 'Inter, sans-serif',
                        }}
                      >
                        Relative
                      </Label>
                    </Col>
                    <Col>
                      <Select
                        isSearchable={false}
                        ref={this.relativeSelectRef}
                        defaultValue={
                          relativeResult.length > 0 && relativeResult[0]
                        }
                        options={relativeResult}
                        isLoading={relativeLoading}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              )}
              {this.state.starhealthUser && (
                <>
                  <FormGroup>
                    <Row>
                      <Col className="col-3">
                        <Label
                          style={{
                            fontFamily: 'Inter, sans-serif',
                          }}
                        >
                          Policy Number/Name
                        </Label>
                      </Col>
                      <Col>
                        <Select
                          className="h-10"
                          isSearchable={false}
                          ref={this.policySelectRef}
                          options={this.state.policyResult}
                          isLoading={relativeLoading}
                          onBlur={this.onPolicyBlur}
                          components={{ SingleValue }}
                          getOptionLabel={GetOptionLabel}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col className="col-3">
                        <Label
                          style={{
                            fontFamily: 'Inter, sans-serif',
                          }}
                        >
                          Dependents{' '}
                        </Label>
                      </Col>
                      <Col>
                        <Select
                          isSearchable={false}
                          ref={this.dependantSelectRef}
                          options={dependentOptions}
                          isLoading={relativeLoading}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Date:
                    </Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="date"
                      onChange={this.handleDateChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Select Slot:
                    </Label>
                  </Col>
                  <Col>
                    {!this.state.doctorSlots ? (
                      <Select
                        ref={this.slotSelectRef}
                        isSearchable={false}
                        options={timeSlots}
                      />
                    ) : (
                      <FormGroup>
                        <Row>
                          <Col className="col-4">
                            <TimePicker
                              className="w-full"
                              onChange={(_, date) => {
                                console.log(date);
                                const newTime =
                                  date.substring(0, 3) +
                                  date.substring(3, 5) +
                                  ' ' +
                                  date.substring(9);
                                this.setState({
                                  appointmentStartTime: newTime,
                                });
                              }}
                              placeholder="Start Time"
                              format="hh:mm:00 A"
                              defaultValue={
                                this.state.appointmentStartTime
                                  ? moment(
                                      this.state.appointmentStartTime,
                                      'hh:mm:00 A'
                                    )
                                  : undefined
                              }
                            />
                          </Col>
                          <Col className="col-4">
                            <TimePicker
                              className="w-full"
                              onChange={(_, date) => {
                                const newTime =
                                  date.substring(0, 3) +
                                  date.substring(3, 5) +
                                  ' ' +
                                  date.substring(9);
                                this.setState({ appointmentEndTime: newTime });
                              }}
                              format="hh:mm:00 A"
                              placeholder="End Time"
                              defaultValue={
                                this.state.appointmentEndTime
                                  ? moment(
                                      this.state.appointmentEndTime,
                                      'hh:mm:00 A'
                                    )
                                  : undefined
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </form>
            <Alert
              color="warning"
              isOpen={this.state.showFormWarning}
              toggle={this.onAlertDismiss}
            >
              {this.state.alertMessage}
            </Alert>
            {this.state.userDetails && (
              <div>
                <h4>User Details</h4>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      User Name :{' '}
                    </Label>
                  </Col>
                  <Col>
                    <span>{this.state.userDetails.userName}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Sponsor :{' '}
                    </Label>
                  </Col>
                  <Col>
                    <span>{this.state.userDetails.sponsorName}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Gender :{' '}
                    </Label>
                  </Col>
                  <Col>
                    <span>{this.state.userDetails.gender}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Age :{' '}
                    </Label>
                  </Col>
                  <Col>
                    <span>{this.state.userDetails.age}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3">
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      policy :{' '}
                    </Label>
                  </Col>
                  <Col>
                    {this.state.userDetails.policyDetails.length &&
                      this.state.userDetails.policyDetails.map((details) => (
                        <span>{details.policyName}, </span>
                      ))}
                  </Col>
                </Row>
              </div>
            )}
            {this.state.doctorSlots && (
              <SectionInnerContainer>
                <SectionHeader>Doctor Slots &nbsp;</SectionHeader>
                <SectionBody>
                  {!loading &&
                   this.state.doctorSlots.map((slot) => (
                      <DetailRow key={slot.dateKey}>
                        <DetailName>{`${slot.formattedDateKey}:`}</DetailName>
                        <DetailText>
                          {slot.slots
                            .slice(0, 4)
                            .map((value) => `${value.timings}`)
                            .join(', ')}{' '}
                          {slot.slots.length > 4 && (
                            <span
                              style={{
                                color: 'green',
                                cursor: 'pointer',
                                fontWeight: 700,
                              }}
                              onClick={() =>
                                this.setState({
                                  doctorSlotModalInfo: {
                                    modal: true,
                                    data: slot.slots,
                                    date: slot.formattedDateKey,
                                  }
                                })
                              }
                            >
                              ...more
                            </span>
                          )}
                        </DetailText>
                      </DetailRow>
                    ))}
                </SectionBody>
              </SectionInnerContainer>
            )}
            <Modal
              isOpen={this.state.doctorSlotModalInfo.modal}
              onCancel={() => this.setState({ doctorSlotModalInfo: { modal: false, data: []} })}
            >
              <ModalHeader
                className="bg-primary"
                toggle={() => this.setState({ doctorSlotModalInfo: { modal: false, data: []} })}
              >
                Doctor Slots
              </ModalHeader>
              <ModalBody>
                <DetailRow>
                  <DetailName>{`${this.state.doctorSlotModalInfo.date}:`}</DetailName>
                  <DoctorSlotBox>
                    {this.state.doctorSlotModalInfo.data && this.state.doctorSlotModalInfo.data.map((value) => (
                      <p
                        style={{
                          padding: '6px',
                          backgroundColor: 'skyblue',
                          borderRadius: '8px',
                          margin: '4px',
                        }}
                      >
                        {value.timings}
                      </p>
                    ))}
                  </DoctorSlotBox>
                </DetailRow>
              </ModalBody>
            </Modal>
          </ModalBody>
          <ModalFooter
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            {loading ? (
              <LoadingComponent />
            ) : (
              <Button
                style={{
                  backgroundColor: 'rgb(113, 79, 255)',
                  width: '40%',
                  borderColor: 'rgb(113, 79, 255)',
                  height: '44px',
                }}
                onClick={this.placeRequest}
              >
                Place Request
              </Button>
            )}
            <Button
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                width: '40%',
                borderColor: 'rgb(113, 79, 255)',
                color: 'rgb(113, 79, 255)',
                height: '44px',
              }}
              onClick={this.toggle}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PlaceRequest;

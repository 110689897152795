import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import { FaTimes, FaSearch, FaUser } from 'react-icons/fa';
import { FaCopy } from "react-icons/fa";
import { searchPolicyDetails } from '../../../services/api/customer-support';
import { toast } from 'react-toastify';



const PolicySearchModal = ({ isOpen, toggle }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [policyDetails, setPolicyDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSearch = async () => {
        setLoading(true);
        const response = await searchPolicyDetails(searchQuery);
        if (response.message === 'success') {
            setPolicyDetails(response.result);
            setLoading(false);
        } else {
            setError(response.message);
            setLoading(false);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    console.log(policyDetails, '[policyDetails]');

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="policy-search-modal">
            <div className='container'>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h4 className="text-primary m-0" style={{ color: '#714FFF' }}>Search IL Policy Details </h4>
                        <button className="btn btn-link text-dark p-0" onClick={() => {
                            toggle();
                            setSearchQuery('');
                            setPolicyDetails([]);
                            setLoading(false);
                            setError('');
                        }}>
                            <FaTimes size={20} />
                        </button>
                    </div>

                    <div className="search-input-container position-relative mb-4">
                        <div className="input-group border rounded-pill overflow-hidden">
                            <span className="input-group-text bg-white border-0">
                                <FaSearch className="text-muted" />
                            </span>
                            <input
                                type="text"
                                className="form-control border-0 shadow-none"
                                placeholder="Search by policy number"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {loading && <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                        </div>
                    </div>}

                    {policyDetails.length > 0 && 
                    <div>
                        <div className="policy-details mb-4">
                            <div className="d-flex justify-content-between mb-3 align-items-center">
                                <span className="fw-bold">Policy Number:</span>
                                <div className="d-flex align-items-center">
                                    <span className="me-2">{policyDetails[0].policyNumber}</span>
                                    <button 
                                        className="btn p-1" style={{ marginLeft: '10px' }}
                                        onClick={() => handleCopy(policyDetails[0].policyNumber)}
                                    >
                                        <FaCopy size={18} />
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mb-3 align-items-center">
                                <span className="fw-bold">Name:</span>
                                <div className="d-flex align-items-center">
                                    <span className="me-2">{policyDetails[0].name}</span>
                                    <button 
                                        className="btn p-1" style={{ marginLeft: '10px' }}
                                        onClick={() => handleCopy(policyDetails[0].name)}
                                    >
                                        <FaCopy size={18} />
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mb-3 align-items-center">
                                <span className="fw-bold">Email Id:</span>
                                <div className="d-flex align-items-center">
                                    <span className="me-2">{policyDetails[0].email}</span>
                                    <button 
                                        className="btn p-1" style={{ marginLeft: '10px' }}
                                        onClick={() => handleCopy(policyDetails[0].email)}
                                    >
                                        <FaCopy size={18} />
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mb-3 align-items-center">
                                <span className="fw-bold">Mobile Number:</span>
                                <div className="d-flex align-items-center">
                                    <span className="me-2">{policyDetails[0].userPhone}</span>
                                    <button 
                                        className="btn p-1" style={{ marginLeft: '10px' }} 
                                        onClick={() => handleCopy(policyDetails[0].userPhone)}
                                    >
                                        <FaCopy size={18} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                    

                    <button style={{ backgroundColor: '#714FFF' }}
                        className="btn btn-primary w-100 py-2"
                        onClick={() => {
                            toggle();
                            setSearchQuery('');
                            setPolicyDetails([]);
                            setLoading(false);
                            setError('');
                        }}
                        >
                        Done
                        </button>
                    </div>
                    }

                    {error && <div className="text-center">
                        <p className="text-danger">{error}</p>
                    </div>}
                </div>
            </div>
        </Modal>
    );
}

export default PolicySearchModal;
